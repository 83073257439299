var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "app flex-row align-items-center bg-gray-50"
  }, [_c('Toast'), this.isErrorPage ? _c('div', {
    staticClass: "flex-row align-items-center"
  }, [_c('b-jumbotron', {
    staticClass: "bg-transparent",
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm._v("Oops, There is Some Error")];
      },
      proxy: true
    }], null, false, 2855500917)
  }, [_c('div', {
    staticClass: "p-3"
  }, [this.message ? _c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v(_vm._s(this.message))]) : _c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v("Error Not Found, Please Kindly Try Again")])]), _c('router-link', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "size": "md",
      "to": {
        path: '/'
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward btn-sm btn-dark"
  }), _vm._v(" Return To Home Page ")])], 1)], 1) : _c('div', {
    staticClass: "container flex relative justify-center h-screen items-center bg-gray-50 text-left"
  }, [_c('div', {
    staticClass: "w-2/5"
  }, [_vm._m(0), _c('div', {
    staticClass: "rounded-lg h-auto p-4 border shadow-md flex justify-center bg-white"
  }, [_c('div', {
    staticClass: "flex-col w-full justify-center"
  }, [_c('h4', {
    staticClass: "font-bold mt-4 mb-3"
  }, [_vm._v("Change Password")]), _c('p', {
    staticClass: "text-base"
  }, [_vm._v("Enter your new password and retype it to confirm.")]), _c('div', {
    staticClass: "mt-0 w-full"
  }, [_c('label', {
    staticClass: "block w-full mb-2 text-sm font-medium text-gray-700",
    attrs: {
      "for": "password"
    }
  }, [_vm._v("New Password")]), _c('div', {
    staticClass: "relative w-full"
  }, [(_vm.showPassword ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.Form.password,
      expression: "Form.password"
    }],
    staticClass: "shadow-sm text-gray-500 bg-gray-50 border p-2.5 w-full rounded-lg",
    attrs: {
      "placeholder": "e.g.: Password01^",
      "required": "",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.Form.password) ? _vm._i(_vm.Form.password, null) > -1 : _vm.Form.password
    },
    on: {
      "input": _vm.validatePassword,
      "change": function ($event) {
        var $$a = _vm.Form.password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.Form, "password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.Form, "password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.Form, "password", $$c);
        }
      }
    }
  }) : (_vm.showPassword ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.Form.password,
      expression: "Form.password"
    }],
    staticClass: "shadow-sm text-gray-500 bg-gray-50 border p-2.5 w-full rounded-lg",
    attrs: {
      "placeholder": "e.g.: Password01^",
      "required": "",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.Form.password, null)
    },
    on: {
      "input": _vm.validatePassword,
      "change": function ($event) {
        return _vm.$set(_vm.Form, "password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.Form.password,
      expression: "Form.password"
    }],
    staticClass: "shadow-sm text-gray-500 bg-gray-50 border p-2.5 w-full rounded-lg",
    attrs: {
      "placeholder": "e.g.: Password01^",
      "required": "",
      "type": _vm.showPassword ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.Form.password
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.Form, "password", $event.target.value);
      }, _vm.validatePassword]
    }
  }), !_vm.showPassword ? _c('svg', {
    staticClass: "absolute right-2 top-1/2 text-gray-500 transform -translate-y-1/2 h-5 w-5 cursor-pointer",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    },
    on: {
      "click": _vm.toggleShowPassword
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-width": "2",
      "d": "M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
    }
  }), _c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-width": "2",
      "d": "M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    }
  }), _vm._v(" :class=\"showPassword ? 'text-gray-700' : 'text-gray-400'\" ")]) : _c('svg', {
    staticClass: "absolute text-gray-500 right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 cursor-pointer",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    },
    on: {
      "click": _vm.toggleShowPassword
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    }
  })])])]), _c('div', {
    staticClass: "mt-3 mb-2 w-full"
  }, [_c('label', {
    staticClass: "block w-full mb-2 text-sm font-medium text-gray-700",
    attrs: {
      "for": "passwordVerify"
    }
  }, [_vm._v("Confirm Password")]), _c('div', {
    staticClass: "relative w-full"
  }, [(_vm.showPassword ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.Form.passwordVerify,
      expression: "Form.passwordVerify"
    }],
    staticClass: "shadow-sm text-gray-500 bg-gray-50 border p-2.5 w-full rounded-lg",
    attrs: {
      "placeholder": "e.g.: Password01^",
      "required": "",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.Form.passwordVerify) ? _vm._i(_vm.Form.passwordVerify, null) > -1 : _vm.Form.passwordVerify
    },
    on: {
      "input": _vm.validatePassword,
      "change": function ($event) {
        var $$a = _vm.Form.passwordVerify,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.Form, "passwordVerify", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.Form, "passwordVerify", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.Form, "passwordVerify", $$c);
        }
      }
    }
  }) : (_vm.showPassword ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.Form.passwordVerify,
      expression: "Form.passwordVerify"
    }],
    staticClass: "shadow-sm text-gray-500 bg-gray-50 border p-2.5 w-full rounded-lg",
    attrs: {
      "placeholder": "e.g.: Password01^",
      "required": "",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.Form.passwordVerify, null)
    },
    on: {
      "input": _vm.validatePassword,
      "change": function ($event) {
        return _vm.$set(_vm.Form, "passwordVerify", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.Form.passwordVerify,
      expression: "Form.passwordVerify"
    }],
    staticClass: "shadow-sm text-gray-500 bg-gray-50 border p-2.5 w-full rounded-lg",
    attrs: {
      "placeholder": "e.g.: Password01^",
      "required": "",
      "type": _vm.showPassword ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.Form.passwordVerify
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.Form, "passwordVerify", $event.target.value);
      }, _vm.validatePassword]
    }
  }), !_vm.showPassword ? _c('svg', {
    staticClass: "absolute right-2 top-1/2 text-gray-500 transform -translate-y-1/2 h-5 w-5 cursor-pointer",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    },
    on: {
      "click": _vm.toggleShowPassword
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-width": "2",
      "d": "M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
    }
  }), _c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-width": "2",
      "d": "M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    }
  }), _vm._v(" :class=\"showPassword ? 'text-gray-700' : 'text-gray-400'\" ")]) : _c('svg', {
    staticClass: "absolute text-gray-500 right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 cursor-pointer",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    },
    on: {
      "click": _vm.toggleShowPassword
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    }
  })])]), !_vm.isPasswordMatch && _vm.Form.passwordVerify !== '' ? _c('div', {
    staticClass: "text-red-500 text-sm mt-2"
  }, [_vm._v(" Password and confirmation do not match. ")]) : _vm._e()]), _c('ul', {
    staticClass: "text-sm mt-4"
  }, [_c('li', {
    staticClass: "flex mb-1 items-stretch align-top justify-start",
    class: {
      'text-green-500': _vm.passwordCriteria.uppercase && _vm.isSaveClicked,
      'text-red-500': !_vm.passwordCriteria.uppercase && _vm.isSaveClicked,
      'text-gray-500': !_vm.isSaveClicked
    }
  }, [_vm._v(" Include uppercase letter (A-Z) ")]), _c('li', {
    staticClass: "flex mb-1 items-stretch align-top justify-start",
    class: {
      'text-green-500': _vm.passwordCriteria.lowercase && _vm.isSaveClicked,
      'text-red-500': !_vm.passwordCriteria.lowercase && _vm.isSaveClicked,
      'text-gray-500': !_vm.isSaveClicked
    }
  }, [_vm._v(" Include lowercase letter (a-z) ")]), _c('li', {
    staticClass: "flex mb-1 items-stretch align-top justify-start",
    class: {
      'text-green-500': _vm.passwordCriteria.minLength && _vm.isSaveClicked,
      'text-red-500': !_vm.passwordCriteria.minLength && _vm.isSaveClicked,
      'text-gray-500': !_vm.isSaveClicked
    }
  }, [_vm._v(" Minimum 8 characters ")]), _c('li', {
    staticClass: "flex items-stretch align-top justify-start",
    class: {
      'text-green-500': _vm.passwordCriteria.numberSymbol && _vm.isSaveClicked,
      'text-red-500': !_vm.passwordCriteria.numberSymbol && _vm.isSaveClicked,
      'text-gray-500': !_vm.isSaveClicked
    }
  }, [_vm._v(" Include number & symbol ")])]), _c('button', {
    staticClass: "w-full p-2.5 mt-2 mb-2 rounded-md",
    class: {
      'opacity-50 bg-gray-300 pointer-events-none text-gray-700': _vm.isButtonDisabled,
      'text-white bg-blue-500': !_vm.isButtonDisabled
    },
    on: {
      "click": _vm.actionReset
    }
  }, [_vm._v(" Save ")])])])])])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-center"
  }, [_c('img', {
    staticClass: "mb-4",
    attrs: {
      "src": require("../../assets/img/gramedia_digital.png"),
      "alt": "Gramedia Digital"
    }
  })]);
}]

export { render, staticRenderFns }